var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"idcardauth"},[_c('mt-header',{attrs:{"title":_vm.$t('idcardauth')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{on:{"click":function($event){return _vm.$router.back(-1)}}},[_c('img',{attrs:{"slot":"icon","src":require("@/assets/back.png"),"height":"20","width":"20"},slot:"icon"}),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":4}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('idcardType')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('idcardTypePlaceholder'),"readonly":"","state":_vm.docTypeState},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.docType, (e) => {
          _vm.form.docType = e.code;
          _vm.form.idcard = '';
          _vm.BEHAVIOR_ADD({
            id: 'P05_C01_S_DOCTYPE',
            newValue: _vm.form.docType
          })
        })}},model:{value:((_vm.Enum.docType.filter(e => e.code == _vm.form.docType)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.docType.filter(e => e.code == _vm.form.docType)[0]||{}), "name", $$v)},expression:"(Enum.docType.filter(e => e.code == form.docType)[0]||{}).name"}},[(_vm.Enum.docTypeLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#4F9B41","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),(_vm.form.docType)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('idcardNumber')))]),(_vm.form.docType == 'PY01')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTT'),expression:"'TTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY03')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#######'),expression:"'#######'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY04')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########000'),expression:"'#########000'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY07')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTTTTTTTTTTTTTTTTT'),expression:"'TTTTTTTTTTTTTTTTTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY08')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY09')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTTTTT'),expression:"'TTTTTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY10')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTTTTTTTT'),expression:"'TTTTTTTTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY11')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTTTTTTTTTT'),expression:"'TTTTTTTTTTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY05')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('##-#######-#'),expression:"'##-#######-#'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY06')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('CRN-###########-#'),expression:"'CRN-###########-#'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):(_vm.form.docType == 'PY02')?_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('A-##-#######-#'),expression:"'A-##-#######-#'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}}):_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('TTTTTTTTTTTTTTTTTT'),expression:"'TTTTTTTTTTTTTTTTTT'"}],attrs:{"placeholder":_vm.$t('idcardNumberPlaceholder'),"state":_vm.idcardState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P05_C02_I_IDCARD',
            newValue: _vm.form.idcard
          })}},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}})],1)]:_vm._e(),_c('upload-image',{ref:"uploadImage",attrs:{"behavior":"P05"},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],2),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }